import React, { Component, ComponentType, ElementRef, useCallback, useEffect, useState, useContext } from 'react';
import { NavLink, useLoaderData, useLocation, useMatch, useMatches, useNavigate, useParams } from 'react-router-dom';
import Loading from 'react-loading';
import { FieldValues, useForm } from 'react-hook-form';
import Card from '../../ui/card';
import { doRequest } from '../../Utils/utils';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import Form, { FormEntry } from '../../ui/form';
import { Textarea } from '../../ui/textarea';
import { DecisionRecord } from './DecisionRecordType';
import SidebarSection from '../../Layout/Sidebar/SidebarSection';
import SidebarSectionRow from '../../Layout/Sidebar/SidebarSectionRow';
import Sidebar from '../../Layout/Sidebar/Sidebar';
import { DecisionRecordColumns, TestRunRecord } from '../TestRunRecords/TestRunRecordType';
import DataTable from '../../ui/dataTable/datatable';
import UserSelector from '../../Layout/Sidebar/UserSelector';
import { useProfileContext } from '../../Utils/user';
import AttachmentsView from '../../Layout/AttachmentsView/AttachmentsView';
import DropdownMenu from '../../ui/dropdownMenu';
import Dialog from '../../ui/dialog';
import NotFound from '../../SharedComponents/NotFound';
import Select from '../../ui/select';

function DecisionRecordView() {
  const navigate = useNavigate()
  const { drId } = useParams();

  const profile = useProfileContext();

  const [canEdit, setCanEdit] = useState(false);
  const [isOwner, setIsOwner] = useState(false);

  const [editing, setEditing] = useState(false);

  const { recordProp } = (useLocation()?.state as { recordProp: DecisionRecord }) || { recordProp: null };

  const loaderData = useLoaderData() as any;

  const [record, setRecord] = useState<DecisionRecord>();
  const [drLoading, setDrLoading] = useState(true);

  const [testRuns, setTestRuns] = useState<TestRunRecord[]>();
  const [loadingTestRecords, setLoadingTestRecords] = useState(true);

  const [preEditState, setPreEditState] = useState<FieldValues>();

  const form = useForm();

  const getDecisionRecord = () => {
    doRequest(`/api/v1/decisionrecords/${drId}`, 'get')
      .then(({ status, data }) => {
        if (status === 200) {
          setRecord(data.record);
        }
        setDrLoading(false);
      })
      .catch((e) => {
        setDrLoading(false);
      });
  };

  const closeEdit = () => {
    form.reset(preEditState);
    setEditing(false);
  };

  const saveEdit = () => {
    const body = form.getValues();

    body.RecordNumber = record?.RecordNumber;

    doRequest(`/api/v1/decisionrecords/${drId}`, 'patch', body)
      .then(({ status, data }) => {
        if (status === 200) {
          getDecisionRecord();
          setEditing(false);
        }
      })
      .catch((e) => {});
  };

  const doDelete = () => {
    doRequest(`/api/v1/decisionrecords/${drId}`, 'delete')
      .then(({ status }) => {
        if (status === 200) {
          navigate('/home')
        }
      }).catch((e) => {
      })
  }

  useEffect(() => {
    if (recordProp) {
      setDrLoading(false);
      setRecord(recordProp);
      return;
    }
    if (loaderData && loaderData.status === 200) {
      setDrLoading(false);
      setRecord(loaderData.data.record);
      return;
    }
    if (!record) {
      getDecisionRecord();
    }
  }, []);

  useEffect(() => {
    if (record) {
      const values: any = {};
      formEntries.forEach((e) => {
        values[e.Name] = record[e.AccessorKey];
      });
      setPreEditState(values);
      form.reset(values);
    }
  }, [record]);

  // Get Test Run Records for this Decision Record
  useEffect(() => {
    if (record != null) {
      doRequest(`/api/v1/decisionrecords/${record.RecordNumber}/testrunrecords`, 'get')
        .then(({ status, data }) => {
          if (status === 200) {
            setTestRuns(data.records);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setLoadingTestRecords(false);
        });
    }
  }, [record]);

  // User Roles

  // Check role for current user to see if they can edit
  useEffect(() => {
    if (record != null && profile != null) {
      if (record.CreatedBy === profile.Email) {
        setIsOwner(true);
        return;
      }

      record.Editors.forEach((e) => {
        if (e.Email === profile.Email) {
          setCanEdit(true);
          return;
        }

        if (e.LDAP.toUpperCase() === profile.LDAP.toUpperCase()) {
          setCanEdit(true);
        }
      });
    }
  }, [record]);

  const addUserRole = (user: string, role: string) => {
    if (!record) {
      return;
    }

    doRequest(`/api/v1/decisionrecords/${record.RecordNumber}/roles`, 'post', {
      ldap: user,
      role,
    })
      .then(({ status }) => {
        if (status === 201) {
          getDecisionRecord();
        }
      })
      .catch((e) => {});
  };

  const removeUserRole = (user: string, role: string) => {
    if (!record) {
      return;
    }

    doRequest(`/api/v1/decisionrecords/${record.RecordNumber}/roles`, 'delete', {
      ldap: user,
      role,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          getDecisionRecord();
        }
      })
      .catch((e) => {});
  };

  // Form Entries

  const [testTools, setTestTools] = useState<string[]>([]);

  useEffect(() => {
    doRequest('/api/v1/testtools', 'get', null, null)
      .then(({ data }) => {
        setTestTools(data.map((tool: any) => tool.name))
      })
  }, [])

  const formEntries: FormEntry[] = [
    {
      Name: 'Thesis',
      Label: 'Thesis',
      InputType: Input,
      AccessorKey: 'Thesis',
    },
    {
      Name: 'TestTool',
      Label: 'Test Tool',
      InputType: Select,
      AccessorKey: 'TestTool',
      SelectOptions: testTools,
    },
    {
      Name: 'TestDescription',
      Label: 'Test Description',
      InputType: Textarea,
      AccessorKey: 'TestDescription',
    },
    {
      Name: 'IdentifiedRisksAndMitigation',
      Label: 'Identified Risks and Mitigations',
      InputType: Textarea,
      AccessorKey: 'IdentifiedRisksAndMitigation',
    },
    {
      Name: 'ImpactedServices',
      Label: 'Impacted Services',
      InputType: Input,
      AccessorKey: 'ImpactedServices',
    },
    {
      Name: 'EstimatedDuration',
      Label: 'Estimated Duration',
      InputType: Input,
      AccessorKey: 'EstimatedDuration',
    },
  ];

  return (
    <div className="max-w-screen-2xl m-auto">
      { record != null && !drLoading
        ? (
          <div className="flex justify-center max-w-8xl items-start gap-4 flex-wrap">
            <div className="flex-1 max-w-screen-xl flex gap-4 flex-col">
              <Card className="w-full">
                <Card.Header className="flex justify-between flex-row items-start gap-4">
                  <div className="flex-1">
                    <Card.Title>
                      { editing
                        ? (
                          <Input
                            {...form.register('TestName')}
                            className="w-full min-h-fit"
                            editing={editing}
                            defaultValue={record.TestName}
                          />
                        ) : record.TestName}
                    </Card.Title>
                    {/* <Card.Description>{record.LastExecutionDate.toLocaleString()}</Card.Description> */}
                  </div>
                  <div className="flex gap-4 m-0">
                    {editing ? (
                      <div>
                        <Button className="hover:bg-red-600 mr-2" onClick={() => closeEdit()}>
                          <i className="icon_close text-lg mr-2" />
                          Cancel
                        </Button>
                        <Button className="hover:bg-green-600" onClick={() => saveEdit()}>
                          <i className="icon_check text-lg mr-2" />
                          Done
                        </Button>
                      </div>
                    ) : null}
                    {!editing && (canEdit || isOwner) ? (
                      <Dialog>
                        <DropdownMenu>
                          <DropdownMenu.Trigger><i className="icon_more-horizontal text-2xl" /></DropdownMenu.Trigger>
                          <DropdownMenu.Content>
                            <DropdownMenu.Item onClick={() => setEditing(!editing)}>Edit</DropdownMenu.Item>
                            <Dialog.Trigger className="w-full">
                              <DropdownMenu.Item>Delete</DropdownMenu.Item>
                            </Dialog.Trigger>
                          </DropdownMenu.Content>
                        </DropdownMenu>
                        <Dialog.Content>
                          <Dialog.Header>
                            <Dialog.Title>Are you absolutely sure?</Dialog.Title>
                            <Dialog.Description>
                              Deleting a Decision Record will also delete any attached Test Run Records. Are you sure you want to delete this Decision Record and its associated Test Run Records?
                            </Dialog.Description>
                          </Dialog.Header>
                          <Dialog.Footer>
                            <Dialog.Close asChild>
                              <Button>Close</Button>
                            </Dialog.Close>
                            <Button className="bg-destructive hover:bg-destructive" onClick={() => doDelete()}>Delete</Button>
                          </Dialog.Footer>
                        </Dialog.Content>
                      </Dialog>
                    ) : null}
                  </div>
                </Card.Header>
                <Card.Content>
                  <Form {...form}>
                    {formEntries.map((e) => (
                      <Form.Field
                        key={e.Name}
                        control={form.control}
                        name={e.Name}
                        disabled={!editing}
                        render={({ field }) => (
                          <>
                            <Form.Item className="flex justify-between align-baseline">
                              <Form.Label className="w-1/3 mt-4">{e.Label}</Form.Label>
                              <Form.Control>
                                { e.InputType !== Select
                                  ? (
                                    <e.InputType
                                      {...field}
                                      editing={editing}
                                      className="w-2/3"
                                    />
                                  )
                                  : (
                                    <Select
                                      onValueChange={(v) => {
                                        field.onChange(v)
                                      }}
                                      defaultValue={field.value}
                                      disabled={!editing}
                                    >
                                      <Select.Trigger className={`w-2/3 ${editing ? 'bg-card border-foreground' : 'text-foreground'}`}>
                                        <Select.Value placeholder={record[e.AccessorKey]} />
                                      </Select.Trigger>
                                      <Select.Content>
                                        {e.SelectOptions?.map((i) => (
                                          <Select.Item key={i} value={i}>{i}</Select.Item>
                                        ))}
                                      </Select.Content>
                                    </Select>
                                  )}
                              </Form.Control>
                            </Form.Item>
                            <Form.Message />
                          </>
                        )}
                      />
                    ))}
                  </Form>
                  <hr className="mt-4" />
                  <AttachmentsView recordNumber={record.RecordNumber} canEdit />
                </Card.Content>
              </Card>
              <Card className="w-full">
                <Card.Header>
                  <div className="flex justify-between items-middle">
                    <Card.Title>Test Runs</Card.Title>
                    <NavLink to={`/testrecords/new?num=${record.RecordNumber}`}>
                      <Button>
                        Create New Test Record
                      </Button>
                    </NavLink>
                  </div>
                </Card.Header>
                <Card.Content>
                  {loadingTestRecords ? <Loading /> : null}
                  {!loadingTestRecords && testRuns != null ? <DataTable data={testRuns} columns={DecisionRecordColumns} /> : null}
                  {!loadingTestRecords && testRuns == null ? <div className="text-center">No Test Runs for this Record</div> : null}
                </Card.Content>
              </Card>
            </div>
            <Sidebar className="">
              <SidebarSection open title="Information">
                <SidebarSectionRow header="Record Number">
                  {record.RecordNumber}
                </SidebarSectionRow>
                <SidebarSectionRow header="Owner/Creater">
                  {record.CreatedBy}
                </SidebarSectionRow>
                <SidebarSectionRow header="Last Executed">
                  {
                    record.LastExecutionDate.toString() === '0001-01-01T00:00:00Z' ? 'Never Run'
                      : new Intl.DateTimeFormat('en-US', {
                        dateStyle: 'long',
                        timeZone: 'America/New_York',
                      }).format(new Date(record.LastExecutionDate))
                  }
                </SidebarSectionRow>
                <SidebarSectionRow header="Created">
                  {
                  new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeStyle: 'long',
                    timeZone: 'America/New_York',
                  }).format(new Date(record.CreatedAt))
                  }
                </SidebarSectionRow>
                <SidebarSectionRow header="Last Updated">
                  {
                  new Intl.DateTimeFormat('en-US', {
                    dateStyle: 'long',
                    timeStyle: 'long',
                    timeZone: 'America/New_York',
                  }).format(new Date(record.CreatedAt))
                  }
                </SidebarSectionRow>
              </SidebarSection>
              <SidebarSection open title="Application">
                <SidebarSectionRow header="Name">
                  <NavLink to={`/applications/${record.ApplicationID}`}>{record.Application.Name}</NavLink>
                </SidebarSectionRow>
                <SidebarSectionRow header="PSRB ID">
                  {record.Application.ParentBusinessApp.PSRBSolutionId}
                </SidebarSectionRow>
                <SidebarSectionRow header="Environment">
                  {record.Application.ParentBusinessApp.LifecyclePhase}
                </SidebarSectionRow>
                <SidebarSectionRow header="Tier">
                  {record.Application.SLATier}
                </SidebarSectionRow>
                <SidebarSectionRow header="Subject Matter Expert">
                  {record.Application.ParentBusinessApp.SubjectMatterExpert.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="Manager">
                  {record.Application.ParentBusinessApp.Manager.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="Director">
                  {record.Application.ParentBusinessApp.Director.Name}
                </SidebarSectionRow>
                <SidebarSectionRow header="VP">
                  {record.Application.ParentBusinessApp.VP.Name}
                </SidebarSectionRow>
              </SidebarSection>
              <SidebarSection open title="Roles">
                <SidebarSectionRow header="Editors">
                  <UserSelector
                    showManagement={isOwner}
                    users={record.Editors || []}
                    addUserCallback={(u) => { addUserRole(u, 'editor') }}
                    removeUserCallback={(u) => { removeUserRole(u, 'editor') }}
                  />
                </SidebarSectionRow>
                <SidebarSectionRow header="Testers">
                  <UserSelector
                    showManagement={canEdit || isOwner}
                    users={record.Testers || []}
                    addUserCallback={(u) => { addUserRole(u, 'tester') }}
                    removeUserCallback={(u) => { removeUserRole(u, 'tester') }}
                  />
                </SidebarSectionRow>
              </SidebarSection>
            </Sidebar>
          </div>
        )
        : null}
      {drLoading && <Loading />}
      {record == null && !drLoading && <NotFound />}
      <div />
    </div>
  )
}

export default DecisionRecordView;
